<template>
	<!-- eslint-disable -->
	<v-container
		v-if="hasPermission($route.meta.permission)"
		id="user-profile"
		tag="section"
	>
		<v-row class="ma-0">
			<v-col cols="12" md="12" class="pa-0">
				<v-card flat class="ma-0">
										<v-col cols="12" sm="4" class="d-flex align-center">
									<span  class="text-h4 text-sm-h2 ">Branches </span>
								
										
									</h4>
								</v-col>
					<v-row class="ma-0">
						<v-col cols="12" sm="4" md="3" xl="2" class="me-auto">
							<v-text-field
								outlined
								hide-details
								dense
								v-model="search"
								label="search branches"
								prepend-inner-icon="mdi-magnify"
							>
							</v-text-field>
						</v-col>
						<v-col
						v-if="hasPermission('Create Branch')"
							cols="12"
							sm="3"
							class="d-flex justify-center justify-sm-end"
						>
							<v-btn
							
								color="primary"
								to="/create-branch"
								><v-icon class="mr-2">mdi-plus</v-icon> New branch</v-btn
							>
						</v-col>
					</v-row>
					<div class="subtitle-1 font-weight-light"></div>

					<v-col cols="12">
						<v-form>
							<v-container class="py-0 mt-8">
								<v-data-table
									dense
									:headers="headers"
									:items="branches"
									:loading="loading"
									@update:options="getBranch"
									:search="search"
									:server-items-length="totalBranches"
									loading-text="Loading... Please wait"
									:footer-props="{ itemsPerPageOptions: [10, 20, 40, 80, 100] }"
									
								>
									<template v-if="hasPermission('Edit Branch')" v-slot:item.id="{ item }">
										<v-icon class="me-2" @click="editBranch(item)"
											>mdi-pencil</v-icon
										>
										<!-- <v-icon @click="archiveBranch(item)">mdi-archive-outline</v-icon> -->
									</template>
								</v-data-table>
							</v-container>
						</v-form>
					</v-col>
				</v-card>
			</v-col>

			<v-col cols="12" md="3" class="mt-0"> </v-col>
			<v-card style="position: absolute !important; opacity: 0">
				<GmapMap
					id="map1"
					ref="map"
					:center="{ lat: 26.3961611, lng: 50.0588903 }"
					:zoom="15"
					map-type-id="terrain"
					style="width: 20px; height: 20px"
				>
					<GmapMarker
						:key="index"
						v-for="(m, index) in markers"
						:position="m.position"
						:clickable="true"
						:draggable="true"
						@click="center = m.position"
						@dragend="updateCoordinates"
					/> </GmapMap
			></v-card>
		</v-row>
	
	</v-container>
	<v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
			</v-col>
		</v-row>
	</v-container>
	<v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable */
import { gmapApi } from "vue2-google-maps";

export default {
	data() {
		return {
			partner_admins: [],
			items: ["Admin", "Viewer", "Member"],
			showLogo: false,
			preview: null,
			image: null,
			nameRules: [(v) => !!v || "Field is required"],
			markers: [],
			map_place: [],
			place: null,
			title: "",
			valid: false,
			action: "add",
			warehouseid: "",
			lat: "",
			long: "",
			name: "",
			mname: {},
			phone: "",
			address: "",
			image: require("@/assets/add-logo.png"),
			branches: [],
			merch_details: [],
			totalBranches: 0,
			loading: true,
			dialog: false,
			user_id: localStorage.getItem("userid"),
			search: "",
			role: localStorage.getItem("role"),
			options: {},
			headers: [
				{
					text: "Name",

					width: 100,
					value: "name",
				},

				{
					text: "Address",
					align: "center",
					sortable: true,
					width: 120,
					value: "location",
				},

				{
					text: "Created",
					align: "center",
					sortable: true,
					width: 70,
					value: "created_at",
				},
				{
					text: "Action",
					align: "center",
					sortable: true,
					width: 70,
					value: "id",
				},
			],
		};
	},
	computed: {
		google: gmapApi,
		searchParams() {
			return {
				query: this.search,
			};
		},
	},
	watch: {
		selected: {
			handler() {
				this.updatepermission();
			},
		},
	},
	mounted() {
		this.getAdminPermissions();
		this.getMerchant();
		this.getBranch();
		this.getPartnerAdmins();
	},
	methods: {
		updateWarehouse() {
			axios
				.post(
					`/api/v3/partner/add-branch-partner?spa=1&id=${this.warehouseid}&seller_id=${this.mname.id}&partner_id=${this.user_id}&branch_name=${this.name}&latitude=${this.lat}&longtitude=${this.long}&subvisor_name=${this.mname.name}&subvisor_phone=${this.mname.users_mobile}&location=${this.address}`
				)
				.then((res) => {
					this.getBranch();
				})
				.catch((err) => console.log(err.response.data))
				.finally(() => (this.dialog = false));
		},
		createWarehouse() {
			axios
				.post(
					`/api/v3/partner/add-branch-partner?spa=1&partner_id=${this.user_id}&seller_id=${this.mname.id}&branch_name=${this.name}&latitude=${this.lat}&longtitude=${this.long}&subvisor_name=${this.mname.name}&subvisor_phone=${this.mname.users_mobile}&location=${this.address}`
				)
				.then((res) => {
					this.getBranch();
				})
				.catch((err) => console.log(err.response.data))
				.finally(() => (this.dialog = false));
		},
		clearForm() {
			this.markers = [];
			this.selectedAreas = [];
			this.mname = {};
			this.name = "";
			this.phone = "";
			this.address = "";
			this.long = "";
			this.lat = "";
		},
		newWare() {
			this.clearForm();
			this.title = "Add a Branch";
			this.action = "add";
			//  let geocoder= new google.maps.Geocoder();
			let self = this;
			navigator.geolocation.getCurrentPosition(
				function (position) {
					self.lat = position.coords.latitude;
					self.long = position.coords.longitude;
					let pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};

					this.markers.push({
						position: {
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						},
					});
					this.geocodeLatLng(pos);
				}.bind(this)
			);
			this.dialog = true;
		},
		geocodeLatLng(map) {
			let geocoder = new google.maps.Geocoder();
			let self = this;
			geocoder.geocode(
				{
					latLng: map,
				},
				function (responses) {
					if (responses && responses.length > 0) {
						self.address = responses[0].formatted_address;
						self.lat = map.lat;
						self.long = map.lng;
					} else {
						alert("Cannot determine address at this location.");
					}
				}
			);
		},
		editBranch(item) {
			this.$router.push({ path: `/edit-branch/${item.id}` });
		},

		getMerchant() {
			axios
				.post(`/api/v3/partner/b2b-merchant-info?partner_id=${this.user_id}`)
				.then((res) => {
					this.merch_details = res.data;
					// this.branches = res.data.warehouse;
				})
				.catch((err) => console.log(err.response.data))
				.finally(() => (this.loading = false));
		},
		getBranch(options = this.options) {
			console.log("options :>> ", options);
			const { sortBy, sortDesc, page, itemsPerPage } = options;

			if (sortBy?.length === 1 && sortDesc?.length === 1) {
				if (sortDesc[0]) {
					const direction = "desc";
					axios
						.get(
							`/api/v3/all-partners-branches??spa=1&all?direction=${direction}&sortBy=${sortBy[0]}&page=${page}&per_page=${itemsPerPage}&status=${this.statuscode}&search=${this.search}`
						)
						.then((res) => {
							this.branches = res.data.data;
							this.totalBranches = res.data.total;
						});
				} else {
					const direction = "asc";
					axios
						.get(
							`/api/v3/all-partners-branches?spa=1&user_id=${this.user_id}&direction=${direction}&sortBy=${sortBy[0]}&page=${page}&per_page=${itemsPerPage}&status=${this.statuscode}&search=${this.search}`
						)
						.then((res) => {
							this.branches = res.data.data;
							this.totalBranches = res.data.total;
						});
				}
			} else if (this.search) {
				axios
					.get(
						`/api/v3/all-partners-branches?spa=1&user_id=${this.user_id}&page=${page}&per_page=${itemsPerPage}&globalsearch=${this.search}&status=${this.statuscode}`
					)
					.then((res) => {
						this.branches = res.data.data;
						this.totalBranches = res.data.total;
					})
					.catch((err) => console.log(err.response.data))
					.finally(() => (this.loading = false));
				this.loading = true;
			} else {
				axios
					.get(
						`/api/v3/all-partners-branches?spa=1&user_id=${this.user_id}&page=${page}&per_page=${itemsPerPage}&globalsearch=${this.search}&status=${this.statuscode}`
					)
					.then((res) => {
						this.branches = res.data.data;
						this.totalBranches = res.data.total;
					})
					.catch((err) => console.log(err))
					.finally(() => (this.loading = false));
			}

			// get by search
		},
		getPartnerAdmins() {
			// this.company_name = localStorage.getItem('name');
			axios
				.get(
					`/api/v3/get-partner-admins?user_id=${localStorage.getItem(
						"userid"
					)}`,
					{
						headers: { "X-Authorization": this.token },
					}
				)
				.then((res) => {
					this.partner_admins = res.data.partner_admins;
				})
				.catch((err) => {
					if (err.response.data.status_code) {
						this.$router.push("/");
					}
				})
				.finally(() => (this.loading = false));
		},
	},
};
</script>
<style>
</style>
